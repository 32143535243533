import axios from 'axios';

export default Object.freeze({
  get: () => axios.get("v1/dossie"),
  getGeoCaptures: () => axios.get("v1/dossie/geoCaptures"),
  getCaptures: (pageSize = 20, page = 0) =>
    axios.get(`v1/dossie/captures?size=${pageSize}&page=${page}`),
  getImage: (image, storage) =>
    new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.get(`v2/dossie/image/${image}?storage=${storage}`, {
          responseType: "blob",
        });
        var reader = new FileReader();
        reader.readAsDataURL(
          new Blob([data], {
            type: "image/jpg",
          })
        );
        reader.onloadend = () => resolve(reader.result);
      } catch (e) {
        reject(e);
      }
    }),
  getBehaviors: () => axios.get("v1/dossie/behaviors"),
});